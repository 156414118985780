/*
  Code that synchronizes params from Angular route to pi-navigation/ai-location that are used in HIL Scripts.
*/
angular.module('app').run(($rootScope) => {
  window.$ip
    .require('@eab-pip/pi-navigation')
    .then(({ location }) => {
      let capturedParamsFromRouter = {};
      let routeChanged = false;
      let locationChanged = false;
      $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
        routeChanged = true;
        capturedParamsFromRouter = toParams;
        updateLocation();
      });
      // Lifecycle StateChangeSuccess(UI-Router) -> Location hashchange event (Location object gets reset)
      // Capture information from UI-Router and update location object.
      $rootScope.$on('$locationChangeSuccess', function (evt) {
        locationChanged = true;
        updateLocation();
      });

      const updateLocation = () => {
        if (routeChanged && locationChanged) {
          location.update(capturedParamsFromRouter);
          routeChanged = false;
          locationChanged = false;
        }
      };
    })
    .catch((e) => {
      console.log(e);
    });
});
