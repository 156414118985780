angular.module('app').config(function (ConsistentRouteConfigurationProvider, $locationProvider, $urlRouterProvider) {
  $locationProvider.hashPrefix('');
  ConsistentRouteConfigurationProvider.activate({
    faultRoute: '/oops//'
  });
  /*
   * Code to redirect #! to #.
   * Regex checks for /!/<urlpath> pattern in URL & refirects to /<urlpath>
   */
  $urlRouterProvider.when(/!\/.+/i, function ($match) {
    const urlWithtoutHash = $match[0].split('!')[1];
    console.info('Redirecting #! to #', urlWithtoutHash);
    return urlWithtoutHash;
  });
});
