angular
  .module('app')
  .service(
    'appFeatureConfigurationService',
    function (
      appConfigModel,
      phxFeatureConfiguration,
      FEATURE_SCHEDULER_NK,
      FEATURE_APPOINTMENTS,
      FEATURE_PERSONAL_REMINDERS,
      surveyUIConfiguration,
      universityBrandingDelegate,
      ngPathToggleService,
      ngContactToggleService,
      majorDataToggleService,
      PROGRAM_PICKER_SHOW_COURSE_MAP,
      PROGRAM_PICKER_SHOW_CREDITS,
      PROGRAM_PICKER_SHOW_DEVELOPMENTAL_REQUIREMENT,
      PROGRAM_PICKER_SHOW_JOB_DEMAND,
      PROGRAM_PICKER_SHOW_SALARY,
      PROGRAM_PICKER_SHOW_TUTION
    ) {
      'use strict';

      this.configureAppFeatures = () => {
        var advisorLabel;

        if (appConfigModel.isFeatureEnabled(FEATURE_APPOINTMENTS)) {
          surveyUIConfiguration.configure({
            enableAppointmentsScheduling: appConfigModel.isFeatureEnabled(FEATURE_APPOINTMENTS)
          });
        }

        if (!_.isEmpty(appConfigModel.model.member_configs)) {
          advisorLabel = (appConfigModel.model.member_configs.advisor_label || 'advisor').toLowerCase();

          phxFeatureConfiguration.features({
            'student-platform.roles.advisor-title.lowercase': {
              assignContent: advisorLabel
            },
            'student-platform.roles.advisor-title': {
              assignContent: _.capitalize(advisorLabel) || 'Advisor'
            }
          });
        }

        ngPathToggleService.setToggle('personalReminder', appConfigModel.isFeatureEnabled(FEATURE_PERSONAL_REMINDERS));
        ngContactToggleService.setToggle(
          'appointmentScheduling',
          appConfigModel.isFeatureEnabled(FEATURE_APPOINTMENTS)
        );

        majorDataToggleService.setToggles({
          salary: appConfigModel.isFeatureEnabled(PROGRAM_PICKER_SHOW_SALARY),
          hiringDemand: appConfigModel.isFeatureEnabled(PROGRAM_PICKER_SHOW_JOB_DEMAND),
          tution: appConfigModel.isFeatureEnabled(PROGRAM_PICKER_SHOW_TUTION),
          devCourses: appConfigModel.isFeatureEnabled(PROGRAM_PICKER_SHOW_DEVELOPMENTAL_REQUIREMENT),
          courseMaps:
            appConfigModel.isFeatureEnabled(FEATURE_SCHEDULER_NK) &&
            appConfigModel.isFeatureEnabled(PROGRAM_PICKER_SHOW_COURSE_MAP),
          credits: appConfigModel.isFeatureEnabled(PROGRAM_PICKER_SHOW_CREDITS)
        });

        const universityColors = {
          primaryColor: _.isEmpty(appConfigModel.getConfigProperty('schoolPrimaryColor'))
            ? '#2a6fb8'
            : appConfigModel.getConfigProperty('schoolPrimaryColor'),
          secondaryColor: _.isEmpty(appConfigModel.getConfigProperty('schoolSecondaryColor'))
            ? '#2a6fb8'
            : appConfigModel.getConfigProperty('schoolSecondaryColor'),
          textColor: _.isEmpty(appConfigModel.getConfigProperty('schoolTextColor'))
            ? '#ffffff'
            : appConfigModel.getConfigProperty('schoolTextColor')
        };

        // inform angular of university colors
        //
        universityBrandingDelegate.update(universityColors);

        // info HIP of university colors
        // wait for HIP to exist on initial load
        // this is swapped because the color application in HIP is more complex than SGD and they have old rules about where colors apply
        // that they want preserved
        //
        const $win = window;
        $win.hiReady(() => {
          $win.HIP.util.setUniversityColor(
            universityColors.secondaryColor,
            universityColors.textColor,
            universityColors.primaryColor
          );
        });
      };
    }
  );
