angular.module('app').config(function (ngDeeplinkConfigProvider, ngDeeplinksStateConfigurationProvider) {
  ngDeeplinkConfigProvider.registerConfigurationArray([
    {
      page: 'todoslist',
      pageDescription: 'Todos List',
      state: 'my.todos',
      deepLinkString: '/dl/todoslist'
    },
    {
      page: 'event',
      pageDescription: 'Path Event',
      state: 'my.todos.details',
      deepLinkString: '/dl/event/:eventId/:repeatedBy',
      stateParams: {
        firstParam: 'eventId',
        secondParam: 'repeatedBy'
      }
    },
    {
      page: 'tip',
      pageDescription: 'Tip Detail',
      state: 'my.todos.tip',
      deepLinkString: '/dl/tip/:eventId/:repeatedBy',
      stateParams: {
        firstParam: 'eventId',
        secondParam: 'repeatedBy'
      }
    },
    {
      page: 'todo',
      pageDescription: 'Staff To-do',
      state: 'my.todos.stafftodo',
      deepLinkString: '/dl/todo',
      constructRouteParams: function (params) {
        const url = window.location.href;
        return {
          staffTodoID: url.split('id=')[1]
        };
      }
    },
    {
      page: 'article',
      pageDescription: 'Article Detail',
      state: 'my.todo.article',
      deepLinkString: '/dl/article/:eventId/:repeatedBy',
      stateParams: {
        firstParam: 'eventId',
        secondParam: 'repeatedBy'
      }
    },
    {
      page: 'ptd',
      pageDescription: 'Personal To-do',
      state: 'my.todos.reminder',
      deepLinkString: '/dl/ptd/:personalTodoID',
      stateParams: {
        firstParam: 'personalTodoID'
      }
    },
    {
      page: 'holdslist',
      pageDescription: 'Holds List',
      state: 'my.holdscenter',
      deepLinkString: '/dl/holdslist'
    },
    {
      page: 'holds',
      pageDescription: 'Hold Detail',
      state: 'my.holdsdetail',
      deepLinkString: '/dl/holds/:type/:id',
      stateParams: {
        firstParam: 'holdTypeId',
        secondParam: 'id'
      }
    },
    {
      page: 'me',
      pageDescription: 'Major Explorer',
      state: 'my.majorexplorer',
      deepLinkString: '/dl/me'
    },
    {
      page: 'res',
      pageDescription: 'Resources Places Page',
      state: 'my.resources.places',
      deepLinkString: '/dl/res'
    },
    // we do not deep-link to a specific category on desktop
    //
    {
      page: 'cat',
      pageDescription: 'Resource Category',
      state: 'my.resources.places',
      deepLinkString: '/dl/cat/:category',
      stateParams: {
        firstParam: 'category'
      }
    },
    {
      page: 'con',
      pageDescription: 'Resource People Page',
      state: 'my.resources.people',
      deepLinkString: '/dl/con'
    },
    // New resources people route
    //
    // {
    //   page: 'con',
    //   pageDescription: 'Resource People Page',
    //   href: '#/my/host/resources/people/list',
    //   deepLinkString: '/dl/con'
    // },
    {
      page: 'det',
      pageDescription: 'Resource Places Detail',
      state: 'my.resourcesDetail',
      deepLinkString: '/dl/det/:id',
      stateParams: {
        firstParam: 'id'
      }
    },
    {
      page: 'sched',
      pageDescription: 'Course Schedule',
      state: 'my.courseSchedule.term',
      deepLinkString: '/dl/sched'
    },
    {
      page: 'class',
      pageDescription: 'Class Detail',
      state: 'my.courseSchedule.detail',
      deepLinkString: '/dl/class/:id',
      stateParams: {
        firstParam: 'id'
      },
      constructRouteParams: () => {
        return false;
      }
    },
    {
      page: 'notif',
      pageDescription: 'Notification Settings',
      state: 'my.notificationsettings',
      deepLinkString: '/dl/notif'
    },
    {
      page: 'conv',
      pageDescription: 'Conversations',
      state: 'my.conversations',
      deepLinkString: '/dl/conv'
    },
    {
      page: 'appt',
      pageDescription: 'My Appointments',
      state: 'my.appointment-dashboard',
      deepLinkString: '/dl/appt',
      href: '#/my/host/as3/appointment-dashboard?tab_name=appointments'
    },
    {
      page: 'fav',
      pageDescription: 'Favorites',
      state: 'my.favorites',
      deepLinkString: '/dl/fav'
    },
    {
      page: 'surveyslist',
      pageDescription: 'Surveys List',
      state: 'my.surveys.list',
      deepLinkString: '/dl/surveyslist'
    },
    {
      page: 'surveys',
      pageDescription: 'Survey Introduction',
      state: 'my.survey-intro',
      deepLinkString: '/dl/surveys/:surveyID/:methodType/:recipientID',
      stateParams: {
        firstParam: 'surveyID',
        secondParam: 'methodType',
        thirdParam: 'recipientID'
      }
    },
    {
      page: 'mpoll',
      pageDescription: 'We Have Some Questions',
      state: 'poll',
      deepLinkString: '/dl/mpoll'
    },
    {
      page: 'poll',
      pageDescription: 'We Have Some Questions',
      state: 'poll',
      deepLinkString: '/dl/poll'
    },
    {
      page: 'study',
      pageDescription: 'Study Buddies',
      state: 'my.studyGroups',
      deepLinkString: '/dl/study'
    },
    {
      page: 'apt',
      pageDescription: 'Appointment Scheduler',
      state: 'my.appointment-new',
      deepLinkString: '/dl/apt',
      href: '#/my/host/as3/appointment-new',
      constructRouteParams: function (params) {
        const url = window.location.href;
        let returnParams = {
          tabname: 'appointments'
        };
        url
          .substring(url.indexOf('?') + 1)
          .split('&')
          .forEach((item) => (returnParams[item.split('=')[0]] = item.split('=')[1]));
        return returnParams;
      }
    },
    {
      page: 'cancel_appt',
      pageDescription: 'Cancel Appointment',
      state: 'my.appointment-cancel',
      deepLinkString: '/dl/cancel_appt',
      href: '#/my/host/as3/appointment-cancel-details',
      constructRouteParams: function (params) {
        const url = window.location.href;
        return {
          id: url.split('id=')[1]
        };
      }
    },
    //"/app/#/dl/pre_appointment_questions/7NXlQElcL6x3"
    {
      page: 'pre_appointment_questions',
      pageDescription: 'Pre-Appointment Questions',
      deepLinkString: '/dl/pre_appointment_questions/:token',
      href: '#/my/host/as3/pre-appointment-questions?token=:token',
      stateParams: {
        firstParam: 'token'
      }
    },
    //"callback_url": "/app/#/dl/post_appointment_feedback/dlSk5FJFHKS_",
    {
      page: 'post_appointment_feedback',
      pageDescription: 'Post Appointment Feedback',
      deepLinkString: '/dl/post_appointment_feedback/:token',
      href: '#/my/host/as3/appointment-feedback?token=:token',
      stateParams: {
        firstParam: 'token'
      }
    },
    {
      page: 'docs',
      pageDescription: 'My Docs',
      state: 'my.reports',
      deepLinkString: '/dl/docs'
    },
    {
      page: 'summarieslist',
      pageDescription: 'Appointment Summaries List',
      state: 'my.appointmentSummary',
      deepLinkString: '/dl/summarieslist'
    },
    {
      page: 'noteslist',
      pageDescription: 'Notes List',
      state: 'my.notes',
      deepLinkString: '/dl/noteslist'
    },
    {
      page: 'reportslist',
      pageDescription: 'Progress Reports List',
      state: 'my.progressReport',
      deepLinkString: '/dl/reportslist'
    },
    {
      page: 'alertslist',
      pageDescription: 'Self Alerts List',
      state: 'my.selfAlerts',
      deepLinkString: '/dl/alertslist'
    },
    {
      page: 'journeys_journey',
      pageDescription: 'Journey Details',

      href: '#/my/host/events/journeys/details/:journey_id',
      deepLinkString: '/dl/journeys_journey/:journey_id',
      stateParams: {
        firstParam: 'journey_id'
      }
    },
    {
      page: 'journeys_step',
      pageDescription: 'Step Details',
      href: '#/my/host/events/checklist/item/Step/:step_id',
      deepLinkString: '/dl/journeys_step/:step_id',
      stateParams: {
        firstParam: 'step_id'
      }
    },
    {
      page: 'events',
      pageDescription: 'Event Check-In',
      deepLinkString: '/dl/events/:token',
      href: '#/my/host/event-campaigns/event/:token/checkin',
      stateParams: {
        firstParam: 'token'
      }
    }
  ]);

  ngDeeplinksStateConfigurationProvider.state();
  ngDeeplinksStateConfigurationProvider.enableDesktopURLNormalization();
});
