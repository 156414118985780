angular.module('app').config(($httpProvider) => {
  'use strict';
  $httpProvider.interceptors.push(($q) => ({
    responseError: (rejection) => {
      const isCampusAPI = rejection.config.headers.isCampusAPI;
      if (rejection.status === 401) {
        if (!isCampusAPI && location.href.match(/authentication/i) === null) {
          log.issue('you are unauthorized ' + rejection.status);
          location.assign(location.pathname + '#/authentication');
          return false;
        }
      }
      return $q.reject(rejection);
    }
  }));
});
