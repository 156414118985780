//Please maintain all global app constants that are not Enums or DTD's or Functions
angular
  .module('app')
  .constant('DefaultRoute', 'my.feed')
  .constant('FEATURE_STANDALONE_SCHEDULER', 'reg-standalone_scheduler')
  .constant('FEATURE_ACCOUNT_HOLD_NK', 'reg-account_hold')
  .constant('FEATURE_BUCKS_ACADEMIC_PLAN_APPROVAL_NK', 'reg-bucks_academic_plan_approval')
  .constant('FEATURE_ONE_CLICK_REGISTRATION_NK', 'reg-one_click_registration')
  .constant('FEATURE_SCHEDULER_NK', 'reg-scheduler')
  .constant('FEATURE_SCHEDULE_APPROVAL_NK', 'reg-schedule_approval')
  .constant('FEATURE_SCHEDULE_REGISTRATION_URL_CRN', 'reg-url_with_crns')
  .constant('FEATURE_REG_SECTION_PIN', 'reg-section_pin')
  .constant('FEATURE_REG_COREQS_ENFORCEMENT', 'reg-coreqs_enforcement')
  .constant('FEATURE_DOCS', 'ssccampus-stc_reports')
  .constant('FEATURE_SEARCH_SERVICE_NK', 'core-search_service')
  .constant('FEATURE_SHOW_STUDENT_NK', 'core-show_student_PII')
  .constant('FEATURE_WAITLIST_NK', 'reg-waitlist')
  .constant('FEATURE_REG_BATCH_REGISTER_WAITLISTED_STUDENTS', 'reg-batch_register_waitlisted_students')
  .constant('FEATURE_SECTION_LABEL_NK', 'section_label')
  .constant('FEATURE_FIRST_TERM_POLL_NK', '_FIRST_TERM')
  .constant('FEATURE_APPOINTMENTS', 'ssccampus-appointment_scheduling')
  .constant('FEATURE_NOTIFICATIONS', 'notification-message_center')
  .constant('FEATURE_RESOURCES', 'inst-resource')
  .constant('FEATURE_ENROLLED_EARNED_CREDITS', 'cat-show_enrolled_and_earned_credits')
  .constant('PROGRAM_PICKER_SHOW_COURSE_MAP', 'programpicker-show_course_maps')
  .constant('PROGRAM_PICKER_SHOW_CREDITS', 'programpicker-show_credits')
  .constant('PROGRAM_PICKER_SHOW_DEVELOPMENTAL_REQUIREMENT', 'programpicker-show_developmental_requirements')
  .constant('PROGRAM_PICKER_SHOW_JOB_DEMAND', 'programpicker-show_job_demand')
  .constant('PROGRAM_PICKER_SHOW_SALARY', 'programpicker-show_salary')
  .constant('PROGRAM_PICKER_SHOW_TUTION', 'programpicker-show_tuition')
  .constant('FEATURE_MAJOR_EXPLORER', 'core-major_explorer')
  .constant('FEATURE_DEGREE_AUDIT', 'academicplan-degree_audit')
  .constant('FEATURE_COURSE_SEARCH', 'reg-course_search')
  .constant('FEATURE_HOLDS', 'holds-holds')
  .constant('FEATURE_CONNECT', 'ssccampus-campus_success_network')
  .constant('FEATURE_PERSONAL_REMINDERS', 'event-personal_to_dos')
  .constant('FEATURE_CLASS_SCHEDULE', 'reg-mg_class_schedule')
  .constant('FEATURE_STUDY_BUDDIES', 'study-buddy')
  .constant('LEANAP_EXPOSE_LEANAP_APIS', 'ap3-academic_planner_v3')
  .constant('FEATURE_FINANCIAL_PLANNER', 'fp-student')
  .constant('FEATURE_SURVEYS', 'survey-survey')
  .constant('FEATURE_SELF_ALERT', 'ssccampus-self_alert_create')
  .constant('FEATURE_TODOS', 'event-to_dos')
  .constant('FEATURE_STUDENT_FEEDBACK', 'core-student_feedback')
  // menu is configured not to advertise the knowledge bot
  //
  .constant('FEATURE_KNOWLEDGE_BOT', '--n/a--');
