const isCampusAPI = (httpRejectionObject) => {
  return !!httpRejectionObject.config.headers.isCampusAPI;
};

const isReportsAPI = (url) => {
  return url.includes('summary_reports') || url.includes('notes') || url.includes('progress_reports');
};

const allowSTCAPIFailure = (httpRejectionObject) => {
  const url = httpRejectionObject.config.url.split('v3/')[1];
  if (isReportsAPI(url)) {
    return true;
  }
  // remove the 'slash'('/') character at the end of the url string, if there is one
  const cleanUrl = url.slice(-1) === '/' ? url.slice(0, -1) : url;
  const allowedListAPIs = [
    'custom_contents',
    'configs',
    'student_resources/reminders',
    'student_resources/pronouns',
    'student_resources/alerts/form_options'
  ];
  return allowedListAPIs.indexOf(cleanUrl) > -1;
};

const displaySTCUnavailability = (httpRejectionObject) => {
  if (allowSTCAPIFailure(httpRejectionObject)) {
    return;
  }
  log.prompt({
    title: 'Service Unavailable',
    body: 'This service is temporarily unavailable, please try again at a later time',
    completeButtonText: 'Ok',
    completeCallback: () => {},
    acceptOnly: true
  });
};

const authFailedHttpStatus = '401';

const handleAPIFailure = ($httpRejectionObject, message = 'There was a problem getting your information.') => {
  if (isCampusAPI($httpRejectionObject)) {
    displaySTCUnavailability($httpRejectionObject);
  } else {
    log.issue(message + ` (${$httpRejectionObject.status})`);
  }
  return false;
};

angular
  .module('app')
  .config(
    function (ngNetworkFailureConfigurationProvider, appNotificationDelegateProvider, $stateProvider, utilityConstant) {
      ngNetworkFailureConfigurationProvider.onResponseFailure('400', function ($httpRejectionObject) {
        handleAPIFailure($httpRejectionObject, 'Sorry, the request failed.');
        return false;
      });

      ngNetworkFailureConfigurationProvider.onResponseFailure(authFailedHttpStatus, function ($httpRejectionObject) {
        if (localStorage.getItem(utilityConstant.REDIRECT_URL_KEY)) {
          return false;
        }
        localStorage.setItem(utilityConstant.REDIRECT_URL_KEY, location.href);
        return false;
      });

      ngNetworkFailureConfigurationProvider.onResponseFailure(authFailedHttpStatus, function ($httpRejectionObject) {
        handleAPIFailure($httpRejectionObject, 'Sorry, you don’t have access to that. Please log in and try again.');
        return false;
      });

      ngNetworkFailureConfigurationProvider.onResponseFailure('403', function ($httpRejectionObject) {
        handleAPIFailure($httpRejectionObject, "Sorry, you don't have access to that.");
        return false;
      });

      ngNetworkFailureConfigurationProvider.onResponseFailure('404', function ($httpRejectionObject) {
        handleAPIFailure($httpRejectionObject, 'Sorry, this information is not available. ');
        return false;
      });

      ngNetworkFailureConfigurationProvider.onResponseFailure('500', function ($httpRejectionObject) {
        handleAPIFailure($httpRejectionObject, 'Sorry, something went wrong. Please try again later.');
        return false;
      });

      ngNetworkFailureConfigurationProvider.onResponseFailure('503', function ($httpRejectionObject) {
        handleAPIFailure($httpRejectionObject, "Sorry, that's not available right now.");
        location.assign('/app/maintenance.html');
        return false;
      });

      ngNetworkFailureConfigurationProvider.onResponseFailure('0', function ($httpRejectionObject) {
        return false;
      });

      /* status codes less than -1 are normalized to zero. -1 usually means the request was aborted, e.g. using a config.timeout. More information about the status might be available in the xhrStatus property.
      https://docs.angularjs.org/api/ng/service/$http#$http-returns
      Incudes CORS & Timeout (65000) issues
    */
      ngNetworkFailureConfigurationProvider.onResponseFailure('-1', function ($httpRejectionObject) {
        if (isCampusAPI($httpRejectionObject)) {
          displaySTCUnavailability($httpRejectionObject);
          return false;
        }
        log.prompt({
          title: 'Network Error',
          body: 'Sorry, request timed out. Please check your network and try again.',
          completeButtonText: 'Ok',
          completeCallback: () => {},
          acceptOnly: true
        });
        return false;
      });
      ngNetworkFailureConfigurationProvider.activate();
    }
  )
  .config(function (NetworkUrlConfigurationProvider) {
    NetworkUrlConfigurationProvider.activate({
      rootUrl: '',
      versionUrl: 'api/v',
      serviceProvider: '',
      includeBaseUrl: false,
      trailingSlash: true,
      forceRefresh: false,
      defaultHeaders: {
        'Content-Type': 'application/json;odata=verbose',
        'user-mode': 'student' // Default to student and is reset if user is not logged in.
      },
      defaultHTTPFields: {
        timeout: 65000,
        xsrfHeaderName: 'X-CSRFToken',
        xsrfCookieName: 'csrftoken',
        withCredentials: true,
        cache: false
      }
    });
  });
