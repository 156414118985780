var app = angular.module('app', [
  'mgcrea.ngStrap.modal',
  'ngAria',
  'pusher-angular',
  'sticky',
  'ui.utils',
  'ct.ui.router.extras.core',
  'ct.ui.router.extras.dsr',
  'ct.ui.router.extras.sticky',
  'ct.ui.router.extras.previous',
  'ngSanitize',
  'vendor',
  'lib',
  'capability-ng-priority-feed',
  'capability-ng-appointments',
  'capability-ng-resources',
  'data-ng-custom-text',
  'data-ng-staff-todos'
]);

angular.module('app').config(function () {
  log.configure({
    serializer: {
      charactersBetweenToken: ''
    },
    logChannels: {
      REQUEST: {
        color: '#f0f0f0'
      },
      VIEW: {
        color: '#FAAC58'
      },
      COMMAND: {
        color: '#454589'
      },
      PROMPT: {
        color: '#454589'
      },
      ERROR: {
        includeStackTrace: true
      }
    }
  });
});

/**
 * @what place the focus on the window when the application first loads
 * @why important for keypresses being active at startup
 */
angular.module('app').run(function () {
  window.focus();
});

angular.element(document).ready(function () {
  angular.bootstrap(document, ['app']);
});
