const CommonAuthenticationConfig = {
  onLoggedInConfirmation: 'loginSuccessCommand',
  onLoggedInFormSuccess: 'loginSuccessCommand',
  authenticationApiPrefix: '/core/',
  defaultBackgroundImageUrl: '%__PATH_RESOURCES__%/login_september_2015.jpg',
  logoClass: 'navigate-header',
  metadata: {
    termsOfUseModal: {
      enable: true
    },
    footerLinks: {
      'http://www.eab.com': 'EAB Home',
      'http://www.eab.com/privacy-policy': 'Privacy Policy'
    }
  }
};

angular
  .module('app')
  .service(
    'applicationLoginConfiguration',
    function applicationLoginConfiguration(authenticationConfiguration, appConfigModel) {
      this.configure = () => {
        const productName = appConfigModel.productName().toLowerCase();
        const welcomeText = appConfigModel.getConfigProperty('auth-login_page_welcome_text');
        CommonAuthenticationConfig.metadata = _.assign(
          { welcomeText: welcomeText },
          CommonAuthenticationConfig.metadata
        );

        if ('aptrinsic' in window) {
          aptrinsic(
            'identify',
            {},
            {
              Product: 'Smart Guidance'
            }
          );
        }

        let authConfig = CommonAuthenticationConfig;
        authenticationConfiguration.configure(authConfig);
      };
    }
  );
