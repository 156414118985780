angular
  .module('app')
  .controller('MyController', function MyController($scope, $state, accountModel, holdsService, loginConfigModel) {
    $scope.isValidState = () => {
      return !$state.is('my.routeModal.poll') && !$state.is('my.todos');
    };

    $scope.screenViewState = () => {
      return $scope.isFullscreenMicro()
        ? 'screen--micro-full'
        : $scope.hasDeferredLayout()
        ? 'screen--deferred-layout'
        : $scope.isWidePanelMicro()
        ? 'screen--micro-panel-wide'
        : 'screen--micro-panel-single';
    };

    $scope.isFullscreenMicro = () => {
      const fullscreenMicroStates = [
        'my.ap3',
        'my.planner',
        'my.schedule',
        'my.routeModal.course_details',
        'my.routeModal.courseDetails'
      ];

      return _.some(fullscreenMicroStates, (str) => $state.current.name.includes(str));
    };

    /**
     * a sub-system will handle page layout
     */
    $scope.hasDeferredLayout = () => {
      const hash = location.hash;
      const deferredLayoutStates = [];

      return _.some(deferredLayoutStates, (str) => hash.includes(str));
    };

    $scope.isWidePanelMicro = () => {
      const hash = location.hash;
      const widePanelMicroStates = ['my.calendar', 'my/host/as3'];

      return _.some(widePanelMicroStates , (str) => $state.current.name.includes(str) || hash.includes(str)  )
    };

    //TODO: Auth Check should be in force_app_config_load.es
    //
    if (!accountModel.isLoggedIn()) {
      accountModel.clear();
      loginConfigModel.clear();
      $state.go('authentication');
    } else {
      holdsService.init();
    }
  });
