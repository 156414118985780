angular
  .module('app')
  .config(function ($urlRouterProvider) {
    // Prevent $urlRouter from automatically intercepting URL changes;
    // this allows you to configure custom behavior in between
    // location changes and route synchronization:
    $urlRouterProvider.deferIntercept();
  })
  .run(
    function (
      $rootScope,
      $urlRouter,
      appConfigModel,
      loadAppConfigCommand,
      loadSchoolListCommand,
      applicationLoginConfiguration,
      appNotificationDelegate,
      ngCustomContentsServant
    ) {
      $rootScope.$on('$locationChangeSuccess', function (evt) {
        // if appConfig is loaded - initiate the state
        if (!appConfigModel.isConfigLoaded()) {
          evt.preventDefault();
          loadSchoolListCommand()
            .then(() => {
              loadAppConfigCommand()
                .then(() => {
                  applicationLoginConfiguration.configure();
                  $urlRouter.sync();
                })
                .catch(() => {
                  appNotificationDelegate.error('Unable to get application configuration');
                });
            })
            .catch(() => {
              appNotificationDelegate.error('Unable to get list of schools');
            });
        }
      });
      $urlRouter.listen();
    }
  );
