// TODO: Refactor this builder.
angular.module('app').factory('MemberConfigsBuilder', function MemberConfigsBuilder(Interface) {
  var DTD = {
    'academicplan-intake_survey_nk': 'string',
    'academicplan-intake_term_preference_nk': 'string',
    'academicplan-intake_time_commitment_nk': 'string',
    'academicplan-intake_generate_plan_nk': 'string',
    autoloader_devreq_warning: 'string',
    'auth-login_button_label': 'string',
    'auth-login_help_button_label': 'string',
    'auth-login_page_welcome_text': 'string',
    'cat-advisor_label': {
      type: 'string',
      writeToKey: 'advisor_label'
    },
    'cat-first_term_source': 'string',
    'cat-in_state_credit_cost': {
      type: 'string',
      writeToKey: 'in_state_credit_cost'
    },
    'cat-section_code_source': {
      type: 'string',
      writeToKey: 'section_code_source',
      defaultValue: 'cd'
    },
    'cat-section_label': {
      type: 'string',
      writeToKey: 'section_label',
      defaultValue: 'CRN'
    },
    'cat-devreq_assignment_strategy': 'string',
    'core-inst_name': 'string',
    'core-school_primary_color_hex': {
      type: 'string',
      writeToKey: 'schoolPrimaryColor'
    },
    'core-school_secondary_color_hex': {
      type: 'string',
      writeToKey: 'schoolSecondaryColor'
    },
    'core-school_text_color': {
      type: 'string',
      writeToKey: 'schoolTextColor'
    },
    'core-user_id_label': 'string',
    'core-campus_url': 'string',
    'core-privacy_policy_url': 'string',
    'reg-scheduler-solver_time_limit': 'number',
    'reg-number_of_seat_requests_per_course': 'number',
    'poll-intake_survey_id': 'string',
    product: 'string',
    PUSHER_KEY: 'string'
  };

  function MemberConfigsDTO(data) {
    Interface.implement(DTD, data, this);
  }

  return MemberConfigsDTO;
});
