angular.module('app').run(function (phxFeatureConfiguration) {
  phxFeatureConfiguration.features({
    'student-platform.roles.advisor-title.lowercase': {
      assignContent: 'advisor'
    },
    'student-platform.roles.advisor-title': {
      assignContent: 'Advisor'
    }
  });
});
