angular.module('app').config(function (ConfigurationProvider) {
  ConfigurationProvider.write({
    about: {
      member: 'EAB',
      application: 'Navigate',
      version: {
        ui: 'release-25-4 | 1088349',
        api: '1'
      }
    }
  });
});
