/*
 * TODO: Remove this and add it to App Shell when the time comes
 *
 * sample usage:
 * featureConfigAdapterProvider.createFeatureRegistry('ngNotifcationSettings', {
 *    serverKey: 'notification-settings',
 *    rootState: 'my.profile.notification-settings'
 * });
 * featureConfigAdapterProvider.registerFeatureState('ngNotifcationSettings', 'app.custom.state')
 */

export interface IFeatureConfig {
  serverKey: string;
  isEnabled: boolean;
  rootState: string;
}

// featureKey: capability name camel cased (i.e., capability-ng-widget -> ngWidget)
interface IRegisteredFeatures {
  [featureKey: string]: IFeatureConfig;
}

interface IRegisteredStates {
  [stateString: string]: string;
}

// serverKey: key in the payload the server gives
interface IAppMemberFeatureConfig {
  [serverKey: string]: string;
}

class FeatureConfigAdapter {
  private registeredFeatures: IRegisteredFeatures;
  private registeredStates: IRegisteredStates;
  private enableFeatureStateBlocking: (stateString: string) => void;

  constructor() {
    this.registeredStates = {};
    this.registeredFeatures = {};
  }

  update(appMemberConfig: IAppMemberFeatureConfig): void {
    _.forEach(this.registeredFeatures, (feature: any): void => {
      feature.isEnabled = appMemberConfig[feature.serverKey];
    });
  }

  registerFeatureState(stateString: string, featureKey: string): void {
    this.registeredStates[stateString] = featureKey;
  }

  // features with a preferred default availabilty should set it in the config
  createFeatureRegistry(featureKey: string, config: IFeatureConfig): void {
    this.registeredFeatures[featureKey] = config;
    if (config.rootState) {
      this.registerFeatureState(config.rootState, featureKey);
    }
  }

  isStateEnabled(stateIncludes: ng.ui.IState): boolean {
    const stateFamilyTree: string[] = _.keys(stateIncludes);
    const stateInRegistry: string = _.first(_.filter(stateFamilyTree, (state: any) => this.registeredStates[state]));

    if (_.isEmpty(stateInRegistry)) {
      return true;
    }

    return this.registeredFeatures[this.registeredStates[stateInRegistry]].isEnabled;
  }

  isStateDisabled(stateIncludes: ng.ui.IState): boolean {
    return !this.isStateEnabled(stateIncludes);
  }

  isFeatureEnabled(featureKey: string): boolean {
    return !!this.registeredFeatures[featureKey].isEnabled;
  }

  isFeatureDisabled(featureKey: string): boolean {
    return !this.registeredFeatures[featureKey].isEnabled;
  }

  getFeature(featureKey: string): IFeatureConfig {
    return this.registeredFeatures[featureKey];
  }

  $get($rootScope: ng.IRootScopeService, $state: ng.ui.IStateService) {
    let featureStateBlockingIsEnabled: boolean = false;
    let _disabledState: string = '';

    this.enableFeatureStateBlocking = (disabledState = 'my.feature-unavailable'): void => {
      if (featureStateBlockingIsEnabled) {
        throw new Error(`feature state blocking is already enabled for ${_disabledState}`);
      }

      $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
        if (this.isStateDisabled(toState.$$state().includes)) {
          event.preventDefault();
          $state.go(disabledState, { prevState: fromState, prevParam: fromParams });
        }
      });

      _disabledState = disabledState;
      featureStateBlockingIsEnabled = true;
    };

    return this;
  }
}

angular.module('app').provider('featureConfigAdapter', FeatureConfigAdapter);
