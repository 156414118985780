type IBrandCategory = any;

/**
 * copied from core-js-brand which holds
 * old phoenix color system
 *
 * @deprecated
 */
const Brand = {
  color: {
    'brand-color--gray': '#333d47',
    'brand-color--white': '#fff'
  }
};

export interface IUtilityConstant {
  DEFAULT_ROUTE: string;
  ERROR_ROUTE: string;
  DEFAULT_COLOR: IBrandCategory;
  DEFAULT_WHITE: IBrandCategory;
  CSS_DEFAULT: string;
  REDIRECT_URL_KEY: string;
}

const utilityConstant: IUtilityConstant = {
  DEFAULT_ROUTE: `my.feed`,
  ERROR_ROUTE: `oops`,
  DEFAULT_COLOR: '#333d47',
  DEFAULT_WHITE: '#fff',
  CSS_DEFAULT: `default`,
  REDIRECT_URL_KEY: `sgdRedirectUrl`
};

angular.module('app').constant('utilityConstant', utilityConstant);
