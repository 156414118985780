angular
  .module('app')
  .service(
    'loadAppConfigCommand',
    function loadAppConfigCommandDefinition(
      defineCommand,
      $http,
      appConfigModel,
      featureConfigAdapter,
      ngMemberConfigurationDatastore,
      appFeatureConfigurationService,
      ngCapabilityConfigurationService
    ) {
      return defineCommand('loadAppConfigCommand', function loadAppConfigCommand(deferred) {
        $http
          .get('/core/appconfig')
          .then(function (response) {
            appConfigModel.update(response.data);
            ngMemberConfigurationDatastore.update(response.data);
            featureConfigAdapter.update(appConfigModel.model.member_features);
            appFeatureConfigurationService.configureAppFeatures();
            ngCapabilityConfigurationService.configure();
            deferred.resolve(appConfigModel);
          })
          .catch(function (e) {
            log.issue('failed to load application configuration');
            deferred.reject(e.data);
          });
      });
    }
  );
