angular
  .module('app')
  .controller('forbiddenController', function forbiddenController($scope, $http, $state, accountModel) {
    'use strict';

    $scope.attemptLogin = function attemptLogin() {
      $http.delete('core/login/userpass/').then(function () {
        accountModel.clear();
        $state.go('authentication');
      });
    };
  });
