angular
  .module('app')
  .config(function (academicPlannerStateConfigurationProvider) {
    academicPlannerStateConfigurationProvider.state();
  })
  .run(function (ngAPDataConfiguration, $injector) {
    ngAPDataConfiguration.configure({
      featureToggles: {
        FEATURE_BUCKS_ACADEMIC_PLAN_APPROVAL_NK: $injector.get('FEATURE_BUCKS_ACADEMIC_PLAN_APPROVAL_NK'),
        FEATURE_SCHEDULE_APPROVAL_NK: $injector.get('FEATURE_SCHEDULE_APPROVAL_NK')
      },
      services: {
        appNotificationDelegate: $injector.get('appNotificationDelegate'),
        appConfigModel: $injector.get('appConfigModel')
      }
    });
  });
