angular.module('app').config(function ($urlRouterProvider, $stateProvider) {
  'use strict';
  $stateProvider
    .state('startup', {
      url: '/',
      template: '<startup></startup>',
      resolve: {
        _AuthenticationQueryModel: (loginConfigurationCommand, loadAccountCommand) =>
          loginConfigurationCommand().then((login_config) => {
            if (!_.isEmpty(login_config.user_data)) {
              return loadAccountCommand();
            } else {
              return false;
            }
          })
      }
    })
    .state('user-modes', {
      url: '/access',
      template: '<user-modes></user-modes>',
      data: {
        viewTitle: 'User Access'
      },
      resolve: {
        _AuthenticationQueryModel: (loginConfigurationCommand, loadAccountCommand) =>
          loginConfigurationCommand().then((login_config) => {
            if (!_.isEmpty(login_config.user_data)) {
              return loadAccountCommand();
            } else {
              return false;
            }
          })
      }
    })
    .state('tos', {
      url: '/tos/',
      template: '<terms-of-service></terms-of-service>',
      data: {
        viewTitle: 'Terms of Use'
      },
      resolve: {
        _AuthenticationQueryModel: (loginConfigurationCommand, loadAccountCommand) =>
          loginConfigurationCommand().then((login_config) => {
            if (!_.isEmpty(login_config.user_data)) {
              return loadAccountCommand();
            } else {
              return false;
            }
          }),
        // some partners are allowed to auto-accept
        //
        _attemptAutoAccept: (autoAcceptTOUCommand) => {
          return autoAcceptTOUCommand();
        }
      }
    })
    .state('my.feature-unavailable', {
      url: 'feature-unavailable/',
      templateUrl: 'src/global/views/feature/disabled.html',
      controller: function ($state, $stateParams) {
        this.navBack = function navBack() {
          $state.go($stateParams.prevState, $stateParams.prevParam);
        };
      },
      controllerAs: '$ctrl',
      params: {
        prevState: 'my.todos',
        prevParam: {}
      }
    })
    .state('oops', {
      url: '/oops/',
      template: 'src/global/views/oops/oops.html',
      data: {
        viewTitle: 'Error Help'
      }
    })
    .state('forbidden', {
      url: '/forbidden/',
      templateUrl: 'src/global/views/forbidden/forbidden.html',
      controller: 'forbiddenController'
    });
});
