angular
  .module('app')
  .service(
    'loginSuccessCommand',
    function (defineCommand, $stickyState, $deepStateRedirect, $cacheFactory, phxNotificationDelegate) {
      return defineCommand('loginSuccessCommand', function loginSuccessCommand(deferred, $state, accountModel) {
        // Reset cache and path models.
        $stickyState.reset('*');
        $deepStateRedirect.reset();

        $cacheFactory.get('$http').removeAll();
        window.sessionStorage.clear();

        //Check account validity
        if (!accountModel.isValid()) {
          deferred.reject();
        }

        // the startup page should handle all other user routing
        $state.go('startup');

        //Dismiss Session timeout alert
        phxNotificationDelegate.restrictTo('session-timeout').dismissAlertByName('session-logged-out-warning');

        deferred.resolve();
      });
    }
  );
