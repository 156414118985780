angular.module('app').config(function ($stateProvider, $urlRouterProvider) {
  'use strict';
  $urlRouterProvider.when('/login/', '/authentication/');
  $urlRouterProvider.when('/badcas/', 'authentication/remote/failure');
  $urlRouterProvider.when('/successcas/', '/authentication/');
});

angular.module('app').config(function (authenticationStateConfigurationProvider) {
  // add my state
  authenticationStateConfigurationProvider.myState({
    templateUrl: 'src/global/views/my/my.html',
    controller: 'MyController',
    onEnter: (pusherService, accountModel, appConfigModel) => {
      pusherService.startChannel(accountModel.username, {
        key: appConfigModel.getConfigProperty('PUSHER_KEY'),
        cluster: 'mt1'
      });
    }
  });

  const AUTH_STATE_TITLES = {
    authentication: {
      onEnter: (holdsService) => {
        holdsService.disconnect();
      },
      data: {
        viewTitle: 'Authentication'
      }
    },
    'authentication.form': {
      data: {
        viewTitle: 'Authentication Form'
      }
    },
    'authentication.remote': {
      data: {
        viewTitle: 'Authentication'
      }
    }
  };
  // add authentication state
  authenticationStateConfigurationProvider.state(AUTH_STATE_TITLES);
});
