angular.module('app').config(function ($popoverProvider) {
  angular.extend($popoverProvider.defaults, {
    html: true,
    delay: 500,
    container: 'body',
    viewport: {
      selector: 'body',
      padding: 0
    }
  });
});
