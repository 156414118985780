angular
  .module('app')
  .service(
    'loadSchoolListCommand',
    function loadSchoolListCommandDefinition(defineCommand, $http, $location, appNotificationDelegate) {
      return defineCommand('loadSchoolListCommand', function loadSchoolListCommand(deferred) {
        const domain = $location.host();
        if (domain.includes('.eab.com') && !domain.includes('local.eab.com')) {
          const domainPieces = domain.split('.');
          const product = domainPieces[1];
          const actualEnv = domain.split('.').slice(2, -2);
          const registryMap = {
            sandbox: 'sandbox',
            impl: 'implementation',
            demo: 'demo',
            production: 'production'
          };

          let schoolsList;
          if (domain.includes('dev.sgd.eab.com')) {
            const envUrlPart = domain.split('.').slice(1, -2)[0];
            schoolsList = `https://registry.${envUrlPart}.dev.sgd.eab.com/tenants`;
          } else if (domain.includes('dev.navigate.eab.com')) {
            const envUrlPart = domain.split('.').slice(1, -2)[0];
            schoolsList = `https://registry.${envUrlPart}.dev.navigate.eab.com/tenants`;
          } else if (domain.includes('realtime.sgd.eab.com')) {
            schoolsList = `https://registry.realtime.sgd.eab.com/tenants`;
          } else if (domain.includes('staging.sgd.eab.com')) {
            schoolsList = `https://registry.staging.sgd.eab.com/tenants`;
          } else {
            const envUrlPart = _.get(registryMap, `${_.first(actualEnv)}`, `production`);
            schoolsList = `https://registry.${envUrlPart}.sgd.eab.com/tenants`;
          }
          $http
            .get(schoolsList, {
              withCredentials: false
            })
            .then(({ data }) => {
              const findDefault = _.find(data, (school) => _.isEqual(school.tenant_url, `${domain}`));
              if (!_.isEmpty(findDefault)) {
                deferred.resolve();
              } else {
                const alternateDomain =
                  product === 'navigate' ? domain.replace(product, 'guide') : domain.replace(product, 'navigate');
                const findAlternate = _.find(data, (school) => _.isEqual(school.tenant_url, alternateDomain));
                if (findAlternate) {
                  window.location.assign(`https://${findAlternate.tenant_url}/app/`);
                } else {
                  alert("Oops! Looks like you are in the wrong place, Please check your school's url");
                  log.warn('Wrong School URL');
                  deferred.reject();
                }
              }
            });
        } else {
          deferred.resolve();
        }
      });
    }
  );
